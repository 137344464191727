import { NetworkService } from "./network.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of, ReplaySubject } from "rxjs";

import { SwitchboardClient } from "./switchboard-client.service";
import { SwitchboardAuth } from "./switchboard-auth.service";
import { User } from "./types/types";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { createKey, persistAsync } from "./data/persistence";
import { ToastService } from "./toast.service";
import { Router } from "@angular/router";

@Injectable({
	providedIn: "root",
	})
export class UserService {
	private user$: ReplaySubject<User>;
	// private authenticated$: BehaviorSubject
	private state$: BehaviorSubject<any> = new BehaviorSubject({});

	constructor(
		private auth: SwitchboardAuth,
		private sb: SwitchboardClient,
		private netSvc: NetworkService,
		private toaster: ToastService,
		private router: Router
	) { }

	// called before anything GraphQL for notications, analytics, or queries/mutations
	getUser$(): Observable<User | null> {
		if (!this.user$) {
			this.user$ = new ReplaySubject<User | null>(1);

			// Subscribe to the token observable
			this.auth.getToken$().pipe(
				switchMap(token => {
				  if (!token) {
					// Emit null if there is no token
					this.user$.next(null);
					return of(null);
				  }

				// If token is available, proceed to get the user profile
				return this.getUserProfile$().pipe(
					map(user => {
					  this.user$.next(user);
					  return user;
					}),
					catchError((err) => {
					  console.log("Error getting user profile:", err);
					  this.user$.next(null);
					  return of(null);
					})
				  );
				})
			  ).subscribe(); // need to subscribe to make the chain work
			}
		return this.user$.asObservable();
	}

	getUserName$(): Observable<string> {
		const user$ = this.getUser$();
		return user$.pipe(
			map((user) => (user ? `${user.firstName} ${user.lastName}` : ""))
		);
	}

	isUserCampFGrad$(): Observable<boolean> {
		return this.getUser$().pipe(
			map((user) => user ? user.permissions.includes("Role:CampFreightlinerIGraduate") : false)
		);
	}

	getUserGraduationDateCFI$() {
		return this.getUserProfile$().pipe(
			map((user) => {
				if (user) {
					return user.graduationDateCFI;
				} else {
					return "";
				}
			})
		);
	}

	getUserProfile$(): Observable<User> {
		return this.queryCurrentUser();
	}

	refreshUser(user: User) {
		return this.user$.next(user);
	}

	async refetchUser() {
		this.user$.next(await this.queryCurrentUser().pipe(take(1)).toPromise());
	}

	// obtains User from localforage
	private queryCurrentUser(): Observable<User> {
		return from(
			persistAsync(
				createKey("user"),
				this.sb
					.query("currentUserProfile")
					.pipe(
						take(1),
						catchError((err) => {
							console.error(
								"Error getting current user details:",
								err
							);
							return of(null);
						})
					)
					.toPromise(),
				this.netSvc
			)
		);
	}

	needsLogin$(): Observable<boolean> {
		return this.getUser$().pipe(map((user) => !user));
	}

	login(email: string, password: string) {
		return this.auth.login(email, password).pipe(
			switchMap((success) => {
				if (success) {
					return this.queryCurrentUser();
				} else {
					return of(null);
				}
			}),
			tap((user) => {
				return this.user$.next(user);
			}),
			map((user) => !!user),

			take(1)
		);
	}

	logout() {
		this.auth.clearToken();
		this.auth.clearRefresh();
		this.user$.next(null);
		this.router.navigate(["/login"]);
	}

	deleteAccount({email, password}: {email: string, password: string}): Observable<boolean> {
		//get user email
		return this.getUser$().pipe(
			map((user) => {
				if (user && user.email === email) {
					return user.email;
				} else {
					return "";
				}
			}),
			switchMap(email => {
				return this.auth.deleteAccount({email, password}).pipe(
					map((success) => {
						if (success) {
							return true;
						} else {
							return false;
						}
					}),
					catchError(err => {
						console.error("Error deleting account:", err);
						return of(false);
					})
				);
			}),
			take(1)
		);
		
		//check if email matches
	}

	register(userInput: any, activationCode?: string) {
		return this.sb
			.mutate("registerUser", { userInput, activationCode })
			.toPromise();
	}

	async updateUserProfile(userInput: any): Promise<any> {
		if (!userInput) return false;
		const result = await this.sb
			.mutate("updateUser", { userInput })
			.pipe(
				switchMap((success) => {
					if (success) {
						return this.queryCurrentUser();
					} else {
						return of(null);
					}
				}),
				tap((user) => {
					return this.user$.next(user);
				}),
				take(1)
			)
			.toPromise();
		return result;
	}

	async removeNoteFromUser(noteId: string) {
		if (!noteId) return false;
		try {
			const result = await this.sb
				.mutate("removeNote", { id: noteId })
				.pipe(
					switchMap((success) => {
						if (success) {
							return this.queryCurrentUser();
						} else {
							return of(null);
						}
					}),
					tap((user) => {
						return this.user$.next(user);
					}),
					take(1)
				)
				.toPromise();
			return result;
		} catch (e) {
			this.toaster.toast("Hmm...something went wrong. Please try again.");
		}
	}

	async checkActivationCode(
		activationCode: string
	): Promise<{ codeIsValid: boolean; registeredCustomerName: string }> {
		const [activationResult] = await Promise.all([
			this.sb.queryOnce("warrantyRecordByActivationCode", {
				activationCode,
			}),
		]);
		const codeIsValid =
			!!activationResult && !!activationResult.activationCode;
		const registeredCustomerName =
			activationResult && activationResult.registeredCustomerName;

		return {
			codeIsValid,
			registeredCustomerName,
		};
	}

	async activateExistingUser(activationCode: string) {
		const activationResult = await this.sb
			.mutate("updateUserWithActivationCode", { activationCode })
			.toPromise();
		return !!activationResult;
	}

	async checkUserExists(email: string) {
		const userResult = await this.sb.queryOnce("userByEmail", { email });

		return !!userResult && !!userResult.email;
	}

	changePassword(params: {
		password: string;
		email: string;
		token?: string;
		oldPassword?: string;
	}) {
		// this.auth.clearToken();

		return this.auth.changePassword(params).pipe(
			switchMap((success) => {
				if (success) {
					return this.queryCurrentUser();
				} else {
					return of(null);
				}
			}),
			tap((user) => {
				if (user) this.user$.next(user);
			}),
			map((user) => !!user),

			take(1)
		);
	}

	resetPassword(email: string) {
		return this.auth.resetPassword(email);
	}

	hasBeenWelcomed$() {
		return this.getUser$().pipe(
			map((user) => {
				return !!(
					user &&
					user.ownership?.vin &&
					user.ownership?.chassisManufactureDate &&
					user.ownership?.engine?.hp &&
					user.ownership?.engine?.make &&
					user.ownership?.transmission?.make
				);
			})
		);
	}
	
	isDemoMode$() {
		return this.getUser$().pipe(
			filter((user) => !!user),
			map((user) => {
				return user.ownership?.vin == "demo";
			})
		);
	}

	logAppOpened() {
		// if user is not logged in, return early
		if (!this.auth.hasToken()) return;
		try {
			return this.sb.mutate("logAppEvent", { action: "opened", date: new Date().toISOString() }).toPromise();
		} catch (e) {
			console.log(e);
		}
	}

	logSwitchboardEvent({ action, date = new Date().toISOString() }: { action: string; date?: string }) {
		try {
			return this.sb.mutate("logAppEvent", { action, date }).toPromise();
		} catch (e) {
			console.log(e);
		}
	}

	registerDeviceToken(token: string) {
		return this.sb.mutate("registerDeviceToken", { token }).toPromise();
	}

	unregisterDeviceToken(token: string) {
		return this.sb.mutate("unregisterDeviceToken", { token }).toPromise();
	}

	markNotificationAsRead(id: string) {
		return this.sb.mutate("markNotificationAsRead", { id }).toPromise();
	}
}

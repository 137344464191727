import gql from "graphql-tag";

export const defaultAssetFields = gql`
	fragment DefaultAssetFields on Asset {
		id
		assetType
		name
		url
		customFields
		textContent
		file {
			id
			url
			filename
			size
		}
		thumbnail {
			id
			url
			filename
			size
		}
	}
`;

export const defaultAssetCategoryFields = gql`
	fragment DefaultAssetCategoryFields on AssetCategory {
		key
		name
		parent
		assets {
			...DefaultAssetFields
		}
	}

	${defaultAssetFields}
`;

export const defaultUserFields = gql`
	fragment DefaultUserFields on User {
		email
		firstName
		lastName
		permissions
	}
`;

export const extendedUserFields = gql`
	fragment ExtendedUserFields on User {
		birthday
		phone
		ownership {
			vin
			make
			model
			year
			fcocMember
			fcocMemberSince
			maintenanceHistory {
				datePerformed
				actionId
				mileageRef
				id
			}
			mileageHistory {
				id
				date
				mileage
			}
			engine {
				make
				hp
			}
			transmission {
				fluidType
				make
			}
			chassisManufactureDate
			purchaseDate
		}
		primaryAddress {
			street
			city
			state
			zip
		}
		graduationDateCFI
		notes {
			id
			title
			text
			createdDate
			lastModifiedDate
		}
		readNotifications
	}
`;

export const defaultWarrantyRecordFields = gql`
	fragment DefaultWarrantyRecordFields on WarrantyRecord {
		activationCode
		registeredCustomerName
	}
`;

export const defaultLocationCategoryFields = gql`
	fragment DefaultLocationCategoryFields on LocationCategory {
		key
		name
		parent
	}
`;

export const defaultLocationFields = gql`
	fragment DefaultLocationFields on Location {
		id
		name
		category
		tags {
			name
			image {
				url
			}
		}
		phone
		tollFreePhone
		wreckerPhone
		fax
		coordinates {
			latitude
			longitude
		}
		address {
			street
			state
			city
			zip
			country
		}
		oasis
		eliteSupport
		specialServices {
			name
			typeCode
		}
		serviceHours {
			monday {
				open
				close
			}
			tuesday {
				open
				close
			}
			wednesday {
				open
				close
			}
			thursday {
				open
				close
			}
			friday {
				open
				close
			}
			saturday {
				open
				close
			}
			sunday {
				open
				close
			}
		}
	}
`;

export const defaultSavedMapsFields = gql`
	fragment DefaultSavedMapsFields on SavedMap {
		_id
		name
		date
		filters
		user {
			id
			...DefaultUserFields
		}
	}
	${defaultUserFields}
`;

export const defaultChassisFields = gql`
	fragment DefaultChassisFields on Chassis {
		id
		name
		parent {
			name
		}
		description
		logo {
			url
		}
	}
`;

export const defaultOemFields = gql`
	fragment DefaultOemFields on Oem {
		id
		name
		logo {
			url
		}
		dealerLocatorUrl
	}
`;

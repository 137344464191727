import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Preferences } from '@capacitor/preferences';
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, of, Observable } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SwitchboardClientOptions } from "./types";

@Injectable({
	providedIn: "root",
	})
export class SwitchboardAuth {
	token$: BehaviorSubject<string | null>;
  	refreshToken$: BehaviorSubject<string | null>;
  	private options: SwitchboardClientOptions = environment.switchboardOptions;

	constructor(
		private http: HttpClient // private apollo: Apollo, // @Inject(SWITCHBOARD_OPTIONS) private options: SwitchboardClientOptions
	) {
		// Initialize BehaviorSubjects with current values from Preferences API
		this.token$ = new BehaviorSubject<string | null>(null);
		this.refreshToken$ = new BehaviorSubject<string | null>(null);
		this.initializeTokens();

		// Whenever the Observable changes, update the stored token as well
		this.token$.subscribe(async (token) => {
			if (token) {
				await Preferences.set({
				key: 'token',
				value: token,
				});
			} else {
				await Preferences.remove({ key: 'token' });
			}
			});
		
			this.refreshToken$.subscribe(async (token) => {
			if (token) {
				await Preferences.set({
				key: 'refresh',
				value: token,
				});
			} else {
				await Preferences.remove({ key: 'refresh' });
			}
		});
	}

	private async initializeTokens(): Promise<void> {
		const tokenResult = await Preferences.get({ key: 'token' });
		const refreshTokenResult = await Preferences.get({ key: 'refresh' });

		this.token$.next(tokenResult.value);
		this.refreshToken$.next(refreshTokenResult.value);
	}

	login(email: string, password: string) {
		return this.http
			.post<any>(this.options.authServer + "/user/login", {
				email,
				password,
			})
			.pipe(
				switchMap((res) => {
					console.log("login success!", res);
					this.setToken(res.accessToken);
					this.setRefresh(res.refreshToken);

					return this.token$.pipe(
						take(1),
						map((token) => !!token)
					);
				}),
				catchError((err) => {
					console.error("Failed to login", err);
					return of(false);
				})
			);
	}

	loginWithRemoteToken(token: string, email: string, source: string) {
		console.log("logging in service");
		return this.http
			.post<any>(this.options.authServer + "/user/loginWithRemoteToken", {
				email,
				token,
				source,
			})
			.pipe(
				map((res) => {
					console.log("login success!", res);
					this.setToken(res.token);
					// location.reload(); // force graphql to pick up the token.
				}),
				catchError((err) => {
					console.error("Failed to login", err);
					return of(false);
				})
			);
	}

	// Called by getUser in user service, checks valid token and will refresh if needed
	// token expires in 24 hours, refresh is rolling and expires after 60 days
	getToken$() {
		return this.token$.pipe(
			switchMap((token) => {
			  if (!token || this.isExpired(token)) {
				return this.attemptTokenRefresh$().pipe(
				  catchError(err => {
					console.error('Error refreshing token:', err);
					return of(null);
				  })
				);
			  } else {
				return of(token);
			  }
			})
		  );
	}

	hasToken(): boolean {
		return !!this.token$.value;
	}

	setToken(token: string) {
		if (!token) {
			this.clearToken();
			return;
		}

		this.token$.next(token);
	}

	setRefresh(token: string) {
		if (!token) {
			this.clearToken();
			return;
		}

		this.refreshToken$.next(token);
	}

	clearToken() {
		this.token$.next(undefined);
	}

	clearRefresh() {
		this.refreshToken$.next(undefined);
	}

	isExpired(token: string): boolean {
		if (!token) return true;
		try {
			const decoded: any = jwtDecode(token);
			return decoded.exp < Date.now() / 1000; // exp is prior to now, aka expired
		} catch (err) {
			console.error('Error decoding token:', err);
			return true;
		}
	}

	attemptTokenRefresh$(): Observable<string | null>{
		const refreshToken = this.refreshToken$.getValue();
		if (!refreshToken) {
			console.log("No refresh token");
			return of(null);
		}
		if (this.isExpired(refreshToken)) {
			console.log("Refresh token expired");
			return of(null);
		}

		return this.http.post<{ accessToken: string; refreshToken: string }>(
			this.options.authServer + "/user/refresh_token",
			{ refreshToken }
		).pipe(
			map(response => {
				console.log("response", response);
				if (response.accessToken && response.refreshToken) {
					this.setToken(response.accessToken);
					this.setRefresh(response.refreshToken);
					return response.accessToken;
				}
				return null;
			}),
			catchError(err => {
				console.error("Error refreshing token:", err);
				return of(null);
			  })
		);
	}


	verifyPasswordChangeToken(token: string, email: string) {
		return this.http
			.post<any>(
				this.options.authServer + "/user/verifyPasswordChangeToken",
				{
					token,
					email,
				}
			)
			.pipe(
				map((res) => !!res),

				catchError((err) => {
					console.error("Failed to verifyPasswordChangeToken", err);
					return of(false);
				})
			);
	}

	changePassword(params: {
		password: string;
		email: string;
		token?: string;
		oldPassword?: string;
	}) {
		return this.http
			.post<any>(this.options.authServer + "/user/changePassword", params)
			.pipe(
				map((res) => {
					console.log("password change and login success!", res);
					this.setToken(res.token);
					return !!res.token;
				}),

				catchError((err) => {
					console.error("Failed to verifyPasswordChangeToken", err);
					return of(false);
				})
			);
	}

	resetPassword(email: string) {
		return this.http
			.post<any>(this.options.apiServer + "/kiosk/resetPassword", {
				email,
			})
			.pipe(
				map((res) => {
					console.log("Password reset email sent!", res);
					return true;
				}),

				catchError((err) => {
					console.error("Failed to send reset email", err);
					return of(false);
				})
			);
	}

	deleteAccount({email, password}: {email: string, password: string}) {
		return this.http
			.post<any>(this.options.authServer + "/user/deleteAccount", {
				email,
				password
			})
			.pipe(
				map((res) => {
					console.log("Account deleted!", res);
					return true;
				}),
				catchError((err) => {
					console.error("Failed to delete account", err);
					return of(false);
				})
			); 
	}
}
